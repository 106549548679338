@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_colors";
@import "../../../scss/_layout";

.FaqSection {
  @extend .double-spaced-rows;
  .title-col {
    font-size: 2.5em;
    font-weight: bold;
    text-align: center;
    align-content: center;
  }

  .faq-row-wrapper {
    background-color: $maw-grey !important;
  }

  @include media-breakpoint-down(sm) {
    .title-col {
      font-size: 1.5em;
    }
  }
}
