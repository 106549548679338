.CandidateSearchResult {
  width: 100%;

  .name-col {
    text-align: left;
  }

  .job-col {
    text-align: right;
  }

  &.btn {
    border: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
