@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";

.HeroSection {
  @extend .big-spaced-rows;
  padding-top: 5em;

  .hero-section-col {
    @extend .center-vertically, .col-lg-6;
    @include spaced-rows(0.5em);
  }

  .label-col {
    font-size: 1.15em;
  }

  .GetInsightsPane {
    .form-col-input {
      @extend .col-8, .col-xxl-6;
    }

    .form-col-btn {
      @extend .col-xxl-3;
    }
  }

  .CompareInsightsPane {
    .form-col-input {
      @extend .col-xxl-3;
    }

    .form-col-btn {
      @extend .col-xxl-3;
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 2em;
  }
}
