@import "../../../scss/_colors";

.FilterOption {
  background-color: darken($light-background, 5%);
  padding: 0.5em;
  border-radius: 5px;
  white-space: nowrap;

  .close-icon {
    font-size: 1em;
    margin-left: 0.2em;
    cursor: pointer;
    &:hover {
      background-color: $dark-background;
    }
  }
}
.and-span {
  padding: 0.5em;
}
