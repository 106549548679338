@import "../../../scss/_layout";

.RecommendCard {

  @extend .double-spaced-rows;

  .title {
    text-align: center;
  }
  
  .content {
    text-align: center;
  }
}
