@import "../../../scss/_bootstrap-configuration";

.danger {
  color: $danger;
  font-size: 1.5em;
}
.classic {
  color: $maw-blue;
}

.character-word::before{
  content: " ";
}
