@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "../../../scss/_layout";
@import "../../../scss/_index";
@import "../../../scss/_colors";
@import "~bootstrap/scss/_grid";

.ApprovalDetailsJob {
  background-color: $maw-white;
  padding: 4em;
  @extend .simple-spaced-rows;

  .section-title {
    font-size: 1.2em;
    font-weight: bold;
  }

  .discard-action {
    background-color: inherit;
    color: $maw-blue;
  }

  .approval-action-row {
    @extend .justify-content-md-center;
    text-align: center;
    @include custom-spaced-cols(0.5rem);
  }

  .rejected-col {
    @extend .col-md-auto, .col-12;
    text-align: center;
  }

  .approval-col {
    white-space: nowrap;
    @extend .col-4;
  }

  .become-spotter-action-button {
    @extend .btn-secondary;
  }

  .share-row {
    text-align: right !important;
  }

  .action-col {
    text-align: center;
  }

  .become-spotter-col {
    @extend .action-col;
  }

  .icon-col {
    @extend .col-auto;
  }

  .approval-text-col {
    @extend .action-col;
  }

  .icon-block {
    @extend .col-12, .col-lg-4;
  }

  .business-block {
    @extend .icon-block;
  }
  .place-block {
    @extend .icon-block;
  }
  .date-blockk {
    @extend .icon-block;
  }
}
@include media-breakpoint-down(lg) {
  .ApprovalDetailsJob {
    padding: 1em;
  }
}
