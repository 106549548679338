@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_layout";

.doughnut-chart-container {
  position: relative;
  width: 35%;

  @include media-breakpoint-down(md) {
    width: 70%;
  }
}
