.ActionIcon {
  &.btn-link {
    background-color: transparent;
    text-decoration: none;
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
  &.btn {
    // Avoid making icon taller than a regular row of text
    padding: 0;
  }
}
