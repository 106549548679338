@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "../../../scss/_layout";
@import "../../../scss/_colors";
@import "../../../scss/_index";
@import "~bootstrap/scss/_grid";

.CandidateApprovedApplicationBlock {
  @extend .double-spaced-rows;

  .job-details-div {
    @extend .double-spaced-rows;
  }

  .become-spotter-action {
    @extend .btn-secondary;
  }

  .show-detail-job-col {
    @extend .col-auto;
  }

  .show-detail-job-row-containeur {
    @extend .justify-content-center, .justify-content-lg-start;
  }

  .job-approval-col {
    display: table;
    @extend .col-12, .col-lg-6;
  }

  .job-approval-div {
    display: table-cell;
    vertical-align: middle;
    font-size: 1.1em;
    @extend .simple-spaced-rows;
  }

  .congratulation-paragraph {
    text-align: center;
    font-size: 3em;
    font-weight: bold;
  }

  .banner-row {
    @extend .text-center, .text-lg-start;
  }

  .banner-image-col {
    text-align: center;
  }

  .banner-image {
    width: 80%;
    height: auto;
  }
}
