@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";

.SponsorshipBlock {

  @extend .medium-spaced-rows;

  text-align: center;

  .header-block {
    @extend .simple-spaced-rows;

    .subtitle {
      font-size: 2em;
    }
  }

  .how-block {
    @extend .simple-spaced-rows;

    .title {
      font-size: 2em;
      font-weight: bold;
    }

    .step-row {
      justify-content: space-evenly;
    }

    .step-col {
      @extend .col-auto;
    }

    .step-cell {
      @extend .simple-spaced-rows;
      width: 287px;
    }

    .step-img {
      max-width: 100%;
      height: 225px;
    }

    .step-number {
      font-weight: bold;
    }
  }

}
