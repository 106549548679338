@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "../../../scss/_layout";
@import "../../../scss/_colors";
@import "../../../scss/_index";
@import "~bootstrap/scss/_grid";

.CandidateRejectedApplicationBlock {
  @extend .double-spaced-rows;
  .job-details-div {
    @extend .double-spaced-rows;
  }

  .become-spotter-action {
    @extend .btn-secondary;
  }

  .show-detail-job-col {
    @extend .col-auto;
  }

  .show-detail-job-row-containeur {
    @extend .justify-content-center;
    padding-bottom: 5rem;
  }

  .call-to-action-row {
    text-align: center;
  }

  .job-approval-div {
    display: table-cell;
    vertical-align: middle;
    font-size: 1.1em;
    @extend .simple-spaced-rows;
  }
}
