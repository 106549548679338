@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_index";
@import "../../../scss/_layout";
@import "../../../scss/_colors";

.BlogPostPreview {
  margin-bottom: 3em;
  @extend .double-spaced-rows;
  .post-col {
    @extend .col-12, .col-lg-9;
  }

  .post-col-row {
    justify-content: center;
  }

  .image-post {
    border-radius: 5px;
  }

  .title-col {
    text-align: center;
  }

  .title-link {
    font-size: $font-size-base * 1.5;
  }

  .post-card {
    border-radius: 5px;
  }

  .post-body {
    padding: 2em 3em 2em 3em;
    @extend .double-spaced-rows;
  }

  .icon-col {
    @extend .col-12, .col-lg-auto, .text-center, .text-lg-start;
  }

  .action-col {
    text-align: center;
  }

  .post-information-row {
    @include custom-spaced-cols(0.5rem);
    @extend .justify-content-start, .justify-content-lg-center;
  }

  .icon-group {
    white-space: nowrap;
    @extend .col-12, .col-lg-4, .text-center, .text-lg-start;
  }

  .icon-group-date {
    @extend .col-12, .col-lg-auto, .text-center;
  }

  @include media-breakpoint-down(md) {
    .post-body {
      padding: 1em;
    }
  }
}
