@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_layout";
@import "~bootstrap/scss/_grid";

.QuoteSection {
  .icon-col {
    @extend .col-1;
  }

  .text-col {
    @extend .col-11, .simple-spaced-rows;
  }

  .quote-text-col {
    font-size: 2.75em;
    font-weight: 600;
    line-height: 1.2em;
  }

  .quote-source-col {
    font-size: 1.15em;
  }

  .quote-icon {
    width: 110%;
  }

  @include media-breakpoint-down(md) {
    .quote-text {
      font-size: 2em;
    }

    .quote-icon {
      width: 200%;
    }
  }
}
