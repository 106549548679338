@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_index";
@import "../../../scss/_layout";
@import "../../../scss/_colors";

.BlogPostBlock {
  background-color: $maw-white;
  padding: 3em;
  border-radius: 5px;
  @extend .double-spaced-rows;

  .image-row {
    max-height: 100vh;
    width: 100%;
    height: 100%;
  }

  .blog-post-title {
    text-align: center;
  }

  .author-col {
    text-align: center;
    @extend .col-12, .col-md-4, .text-start, .text-md-center;
  }

  .date-col {
    text-align: end;
    @extend .col-12, .col-md-4, .text-start, .text-md-end;
  }
}

.latest-job {
  margin-top: 4em;
}

@include media-breakpoint-down(md) {
  .BlogPostBlock {
    padding: 1em;
  }
}
