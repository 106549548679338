@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";
@import "../../../scss/_index";

.CandidateProfileBlock {
  @extend .widget-block;

  .candidate-profile-block {
    @extend .double-spaced-rows;
  }

  .icon-col {
    @extend .col-auto;
  }

  .name-col {
    font-size: 1.5em;
  }

  .identity-icon {
    font-size: 6em;
    margin-left: -0.2em;
  }

  .indication-col {
    font-size: 0.75em;
  }

  .icon-data-col {
    @extend .data-field;
  }

  .information-display-col {
    text-align: center;
    @extend .simple-spaced-rows;
  }

  .refresh-col {
    text-align: right;
  }

  .link-col {
    margin-top: 0.5em;
    text-align: right;
    @extend .text-lg-end;
  }

  .candidate-contact-col {
    @extend .col-12, .col-lg-6;
  }

  @include media-breakpoint-down(md) {
    .FooterItemGroup {
      font-size: 0.8em;
    }
    .FooterHeaderItem {
      font-size: 1.2em;
    }
  }
}
