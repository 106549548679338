@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_layout";
@import "~bootstrap/scss/_grid";

.LastViewedList {
  @extend .simple-spaced-rows;
  .empty-message {
    font-style: italic;
  }
  .list-item {
    // Remove gutters
    @extend .g-0;

    // Insert an horizontal line before each item
    border-top-style: solid;
    border-width: $border-width;

    // Insert an horizontal line after the last itme
    &:last-of-type {
      border-bottom-style: solid;
    }
  }
}
