@import "../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../scss/_bootstrap-utilities-api";
@import "~bootstrap/scss/_nav";
@import "../../scss/_layout";
@import "../../scss/_colors";

.Menu {
  // Align menu items one under the other when menu is collapsed
  @extend .flex-column, .flex-sm-column, .flex-lg-row;

  .MenuItem {
    @include media-breakpoint-down(lg) {
      .MenuItem-div {
        color: $maw-blue;
      }
    }
    @include media-breakpoint-up(lg) {
      .children-container {
        &:hover {
          text-decoration: underline;
        }
      }
      .MenuItem-div {
        color: $maw-blue;
      }

    }
    // Use some alignments from Bootstrap's NavLink, but without adding an <a> because we use Router
    @extend .nav-link;

    &.menu-item-outlined {
      @include media-breakpoint-up(lg) {
        .children-container {
          color: $maw-blue;
          &:hover {
            text-decoration: none;
          }
        }
        .MenuItem-div {
          border: $border-width solid;
          padding-right: 0.5em;
          padding-left: 0.5em;
          color: $maw-blue;
          &:hover {
            background-color: $maw-blue;
            color: $maw-white;
          }
        }
      }
    }
  }

  .MenuDropdown {
    color: $maw-blue;
    @extend .MenuItem;
    padding: 1em;
    .menu-dropdown-toggle {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    // Override .dropdown-item from Bootstrap
    .dropdown-item {
      color: $maw-blue;
      &:hover,
      &:focus {
        background-color: inherit;
      }

    }
  }

  // When menu collapses (see parent Navbar "expand" breakpoint), we want a different style
  @include media-breakpoint-down(lg) {
    text-align: right;
  }
}
