@import "../../scss/_bootstrap-configuration";

.NotFoundPage {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2em;
  text-align: center;
  font-size: $font-size-base * 2;
}
