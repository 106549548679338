@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";
@import "../../../scss/_index";

.ProfileBlock {
  @extend .spaced-children, .widget-block;

  .displayName {
    @extend .col-auto;
  }

  .label {
    font-weight: bold;
  }

  .icon-col {
    @extend .col-auto;
  }
}
.deleteUpdateContainer {
  padding-top: 1em;
  @extend .spaced-children;
}
