@import "../../scss/_bootstrap-configuration";
@import "../../scss/_layout";
@import "../../scss/_index";
@import "~bootstrap/scss/_grid";

.SignUpPage {
  .SignUpPage-div {
    @extend .simple-spaced-rows;
  }
  // Center items horizontally
  text-align: center;

  .row {
    justify-content: center;
  }
}

.SignUpBlock {
  @extend .double-spaced-rows;
  .singn-up-form-col {
    @extend .col-12, .col-md-7, .col-lg-6;
  }
}

.sign-up-form-div {
  @extend .widget-block;
}
