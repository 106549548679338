@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_layout";

.InsightsSearchBlock {
  @extend .big-spaced-rows;

  h1 {
    font-size: 3.2em;
    line-height: 1.2em;
  }

  @include media-breakpoint-down(md) {
    h1 {
      font-size: 2em;
    }
  }
}
