@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.WysiwygEditComponent {
  .btn-danger {
    color: $maw-white;

    &:hover,
    &:focus,
    &:active {
      color: $maw-white;
    }
  }

  .placeholder {
    color: darkgray;
  }

  .error {
    color: $danger;
  }

  // Common border info for toolbar and editor
  .editor-border {
    border-radius: 0;
    border-style: solid;
    border-width: $border-width;
    border-color: $card-border-color;
  }

  // Set border of toolbar
  .rdw-editor-toolbar {
    @extend .editor-border;
  }

  // Set border of editor
  .rdw-editor-main {
    @extend .editor-border;

    // Same padding as when viewing value
    padding: $input-btn-padding-y $input-btn-padding-x;
  }

  // Put buttons on the right of the editor
  .input-row {
    align-items: center;

    // Remove gutter, we'll set it manually
    @extend .g-0;
  }

  .button-col {
    // Place buttons at the same place as the edit icon so that edit box does not change size
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    .btn {
      padding: 0;
    }

    // Display buttons on top of each other
    & > * + * {
      display: block;
      margin-top: 0.5rem;
    }
  }
}
