@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.GetInsightsPane,
.CompareInsightsPane,
.CompareWithPane {
  .input {
    border-top: none;
    border-right: none;
    border-left: none;
  }

  .btn {
    width: 100%;
  }

  .form-col-input {
    @extend .col-4;
  }

  .form-col-btn {
    @extend .col-4;
  }
}
