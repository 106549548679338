.InsightsNoResultBlock {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2em;

  .no-result-label {
    text-align: center;
    font-size: 2em;
  }

  .no-result-link {
    font-size: 1.25em;
  }
}
