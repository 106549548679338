@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";

.JobSearchPane {
  background: none;
  border: none;

  .job-search-pane-body {
    @extend .double-spaced-rows;
  }

  .job-filter {
    @extend .col-auto, .col-md-auto;
  }

  .filter-option-col {
    white-space: nowrap;
    margin-top: 1em;
    height: 2em;
    @extend .col-auto;
  }

  .keyword-filter-col {
    @extend .col-12, .col-lg-6;
  }

  .filter-results-jobs-row {
    align-items: center;
    @include custom-spaced-cols(0.5rem);
  }

  .filter-results-jobs {
    font-size: 1.5em;
    @extend .col-auto;
  }

  .filter-row {
    @extend .wrap-spaced-children;
  }

  .filter {
    // Space between rows of a filter
    & > * + * {
      margin-top: 0.5rem;
    }

    .filter-title {
      font-weight: bold;
    }

    .filter-suggestion {
      font-style: italic;
    }

    .filter-input-row {
      align-items: center;
    }

    .filter-button-col {
      @extend .col-auto;
      padding-left: 0 !important;
    }

    .link-button {
      padding: 0;
      border: none;
    }
  }
  .searchContainer {
    @extend .wrap-spaced-children;
  }
}

@include media-breakpoint-down(md) {
  .JobSearchPane {
    font-size: 0.8em;
  }
}
