@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_colors";
@import "../../../scss/_layout";
@import "../../../scss/_index";
@import "~bootstrap/scss/_grid";

.PricingSlot {
  @extend .double-spaced-rows;
  .highlighted-title-section {
    background-color: $maw-green !important; 
  }

  .title-section {
    color: $maw-white;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    background-color: $maw-blue;
  }

  .title-div{
    height: (1em * $line-height-base * 3); 
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .price-section {
    font-weight: bold;
    font-size: 1.2em;
  }

  .children-div{
    height: (1em * $line-height-base * 6);
  }

  .signup-button {
    @extend .btn-secondary;
    font-weight: bold;
    margin-bottom: 1em;
  }
}