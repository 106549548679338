@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_index";
@import "../../../scss/_layout";

.CallToActionSection {
  @extend .medium-spaced-rows;

  .action-title-col {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    align-content: center;
    line-height: 1.4em;
    padding-bottom: 1em;
  }

  .action-row {
    padding-bottom: 1em;
    text-align: center;
    align-content: center;
  }
}
