@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";

.JobSearchItem {
  margin-bottom: 1rem;
  border-radius: 5px;
  .name-col {
    text-align: left;
    font-weight: bold;
    font-size: $font-size-base * 1.25;
  }

  .title-col {
    text-align: left;
  }

  .card-row-container {
    @include custom-spaced-cols(1rem);
  }
  .commission {
    font-size: $font-size-base * 2;
    background-color: $maw-green;
    border-radius: 0.3em 0em 0em 0.3em;
  }
  .skills-col {
    text-align: center;
  }
  .commission-title-col {
    font-weight: bold;
  }
  .icon-col {
    @extend .col-auto;
  }
  .recommend-col {
    text-align: right;
  }
  .commission-col {
    @extend .col-12, .col-lg-4, .spaced-children;
    text-align: center;
  }
  .section-title {
    font-weight: bold;
  }
  .companyInformation {
    @extend .spaced-children;
  }
  .card-container {
    margin-bottom: 0;
    padding: 12px;
    @extend .spaced-children;
  }
  .skills-div {
    @extend .simple-spaced-rows;
  }
  .list-row {
    text-align: center;
  }
  @include media-breakpoint-down(lg) {
    .card-container {
      padding-bottom: 0;
    }
    .list-row {
      padding-bottom: 1em;
    }
    .commission {
      background-color: inherit;
    }
    .commission-span {
      background-color: $maw-green;
      border-radius: 5px;
      padding: 0.1em 1em 0.1em 1em;
    }
  }
}
