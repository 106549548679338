@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_index";
@import "../../../scss/_layout";

.HowItWorksEmployeeReferral {
  .title-col {
    font-size: 2.5em;
    font-weight: bold;
    text-align: center;
    align-content: center;
    line-height: 1.4em;
  }

  .subtitle-col {
    font-size: 1.5em;
    text-align: center;
    align-content: center;
  }

  .how-it-works-step-pic-col {
    text-align: center;
    align-content: center;
  }

  .how-it-works-col-container {
    @extend .medium-spaced-rows;
  }

  .how-it-works-col {
    @extend .col-lg-4, .col-12;
  }

  .step-col {
    text-align: center;
    align-content: center;
    font-size: 1.2em;
    font-weight: bold;
  }

  .step-description-col {
    text-align: center;
    align-content: center;
    margin-top: 1em;
    font-weight: bold;
    padding-bottom: 2em;
  }

  .how-it-works-row {
    @include custom-spaced-cols(4rem);
  }

  @include media-breakpoint-down(lg) {
    .title-col {
      font-size: 1.5em;
    }
    .subtitle-col {
      font-size: 1em;
      font-weight: normal;
    }
    .step-description-col {
      font-weight: bold;
    }
    .step-img {
      height: 8em;
    }
  }
}
