@import "../../scss/_colors";

.PageFooter {
  .navbar-collapse {
    justify-content: center;
  }

  .navbar-nav {
    align-items: flex-end;
  }
}
