.Banner {
  margin-top: 2%;
  margin-bottom: 2%;

  .BannerMsg {
    font-size: 32px;
    line-height: 28px;
    width: auto;
    margin-top: 5%;
    margin-bottom: auto;
  }

  .SubBannerMsg {
    font-size: 18px;
    line-height: 24px;
    width: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }
}
