@import "../../../scss/_bootstrap-configuration";

.LoadingError {
  text-align: center;
  color: $error-red;

  .error-icon {
    font-size: $font-size-base * 5;
  }
}
