.JobSearchResultList {
  .button-row {
    text-align: center;
    margin-top: 3em;
  }

  .expand-icon-row {
    text-align: center;
  }
}
