@import "../../scss/_bootstrap-configuration";
@import "../../scss/_layout";
@import "../../scss/_index";

.ContactUsForm {
.radio{
text-align: center;
}
.FormRow{
  text-align: center;
}
.formContainer{
  @extend .spaced-children, .widget-block;
}
}

