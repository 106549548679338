@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "../../../scss/_layout";
@import "../../../scss/_colors";
@import "../../../scss/_index";
@import "~bootstrap/scss/_grid";

.EnumFilter {
  margin-bottom: 0.5rem;
  align-items: center;
  @include custom-spaced-cols(0.5rem);

  .form-control-box {
    @extend .filter-form-control;
  }

  .option {
    background-color: $maw-white;
  }

  .filter-label {
    @extend .col-12, .col-lg-auto;
    padding-right: 0;
  }
}
