@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.ResumeDownloadLink {
  .action-icon {
    color: $tertiary;
    font-size: 1.2em;
    margin-bottom: 0.2em;
  }

  .action-link-download {
    cursor: pointer;
    padding-right: 0;
    text-decoration: underline;
    @extend .col-auto;
  }

  .action-icon-col {
    padding-left: 0;
  }
}
