@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "../../../scss/_layout";

.CandidateUpdateResume {
  .dropbox {
    height: 20rem;
  }

  .form-actions {
    margin-top: 1rem;
    text-align: center;
  }
}
