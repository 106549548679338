@import "../../scss/_bootstrap-configuration";
@import "../../scss/_bootstrap-utilities-api";
@import "~bootstrap/scss/_grid";
@import "../../scss/_layout";
@import "../../scss/_index";
@import "../../scss/_colors";

.SignInForm {
  @extend .simple-spaced-rows;
  .connection-mode-div {
    @extend .simple-spaced-rows;
  }

  .input-row-container {
    justify-content: center;
  }

  .action-links-row {
    justify-content: center;
  }

  .lock-icon-col {
    text-align: center;
  }

  .lock-icon {
    font-size: 5em;
  }

  .form-control-row {
    text-align: center;
  }

  .action-links-col {
    @extend .col-10;
  }

  .sign-up-col {
    text-align: right;
    @extend .col-12, .col-md-6, .text-start, .text-lg-end;
  }
}
