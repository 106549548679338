@import "../../../scss/_colors";

.PopularSearches {
  .btn {
    background-color: $maw-white;
    border-color: $dark-background;
    color: $maw-blue;
    font-size: 1.2em;
    margin: 1em 0.5em 0 0;
    padding: 0.5em;
    width: fit-content;
    border-radius: 0.25em;

    .search-icon {
      margin-right: 0.25em;
    }
  }
}
