@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";
@import "../../../scss/_layout";
@import "~bootstrap/scss/_grid";

.CandidatesBlock {
  @extend .double-spaced-rows;
  .filter-col {
    text-align: center;
    margin-bottom: 5em;
  }

  .button-col {
    @extend .col-12, .col-md-auto, .text-center;
  }
}
