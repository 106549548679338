@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_bootstrap-utilities-api";
@import "../../../scss/_layout";

.ApplicationSearchResult {
  width: 100%;

  &.btn {
    border: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .row-container {
    @include custom-spaced-cols(0.5rem);
  }

  .name-col-container {
    text-align: left;
    @extend .col-12, .col-lg-3, .text-lg-center;
  }

  .name-col {
    text-align: left;
    @extend .col-12, .col-lg-3, .text-lg-center;
  }

  .name-col {
    @extend .col-auto;
  }

  .icon-title-col {
    padding-left: 0.3em;
  }

  .icon-col {
    @extend .col-auto;
    padding-left: 0;
    padding-right: 0.4em;
  }

  .row-container {
    align-items: center;
  }

  .job-col {
    text-align: left;
    @extend .col-12, .col-lg-3;
    font-weight: bold;
    align-items: center;
  }

  .count-day-col {
    @extend .col-12,
      .col-sm-4,
      .col-md-6,
      .col-lg-3,
      .text-start,
      .text-lg-end,
      .order-1,
      .order-lg-2;
  }

  .approval-col {
    @extend .text-center, .order-2, .order-lg-1;
  }

  .approval-row {
    @extend .justify-content-sm-end;
  }

  .approval-col-container {
    @extend .col-sm-10, .col-md-6, .col-lg-12;
  }

  .status {
    font-size: 0.8em;
  }

  @include media-breakpoint-up(lg) {
    .icon-col {
      display: none;
    }
  }
}
