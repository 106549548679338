@import "../../../scss/_bootstrap-configuration";

.PillList {
  // Add space between rows of items but only when they wrap
  $vspace: 0.5rem;
  margin-top: -$vspace;

  // Change default margin between columns
  $hspace: 0.5rem;
  @extend .no-gutters !optional;
  margin-left: -$hspace !important;
  margin-right: 0 !important;

  .Pill {
    text-align: left;
    // Add space between columns
    margin-left: $hspace;
    // Add space between rows of items but only when they wrap
    margin-top: $vspace;
    // Empty coloured space around text
    padding: 0.05rem 0.3rem;
    color: $maw-blue;
  
    background-color: darken($light-background, 5%);
    // Make badges look like buttons
    &.badge {
      line-height: $btn-line-height;
      vertical-align: middle;
      font-weight: $btn-font-weight;
    }
  }

  .tag-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
